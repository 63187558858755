<template>
  <card
    :content="content"
    :schema="getSchemaDealers"
    :form-data="getDataDealers"
    :editable="getEditableDealers"
    @update-form="updateDealers"
    @submitAction="addDealers"
  />
</template>

<script>
import Card from "../components/Card";
import confDealers from "../conf/confDealers";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Dealers",
  components: { Card },
  title() {
    const locationName =
      this.$store.getters["company/get_locationName"] ??
      localStorage.getItem("locationName");
    return this.$t(this.$route.meta.title) + " – " + locationName;
  },

  data() {
    return {
      content: confDealers.content,
      hidden: true
    };
  },
  created() {
    this.$store.dispatch("dealers/loadDealers");
  },
  computed: {
    ...mapGetters("dealers", [
      "getDataDealers",
      "getSchemaDealers",
      "getEditableDealers"
    ])
  },
  methods: {
    ...mapActions("dealers", ["updateDealers", "addDealers", "loadDealers"])
  }
};
</script>

<style scoped></style>
